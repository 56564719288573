div.comment {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    padding-top:10px;
}

.flex-container {
    display: flex;
}
.shadow {
    -moz-box-shadow:    inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow:         inset 10px 5px 5px #000000;
 }